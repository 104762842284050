export function front_news() {
  new Swiper ('.js-newsList', {
    speed: 800,
    slidesPerView: 1.2,
    spaceBetween: 28,
    autoplay: {
      delay: 5000,
    },
    pagination: '.swiper-pagination',

    breakpoints: {
      768: {
        slidesPerView:4.2,
      },
    },
  });
}
