export function mv() {
  new Swiper ('.js-mvslider', {
    effect: "fade",
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 3000,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
  });
}
